<template>
	<div id="user" class="user position_relative" :class="{user_size:this.UPDATE_QUOTE.video_link != '' && !showQuote}">
		<div class="container ">
			<div class="row">
				<div class="col-md-12">

          <div class="main-box">


            <div class="border_citate">

              <div class="box" v-if="this.UPDATE_QUOTE.video_link != '' && showQuote">
                <div class="image-box" :style="`${'background:url(https://zitatnik.ru' + this.UPDATE_QUOTE?.image}); background-size: cover; background-repeat: no-repeat;
              background-position:center center; `">
                  <!--                <img class="image" :src="'https://zitatnik.ru'+ this.UPDATE_QUOTE?.image" alt="">-->
                </div>
              </div>
            </div>

            <div class="video" v-if="this.UPDATE_QUOTE.video_link != '' && !showQuote">
                            <div v-html="videoSize()"></div>
            </div>


            <div class="text-btn">
              <h1 class="h1_citata" v-if="showQuote">Цитата</h1>
              <p class="p_citata" v-if="showQuote">{{this.UPDATE_QUOTE.quote}}</p>
              <p class="text_warning" v-if="this.$store.state.alert_quote">{{this.GET_API_NOTIFICCATIONS[0].quotes_limit}}</p>
              <!-- <a class="btn" @click="GET_QUOTE()">Показать цитату</a> -->

              <button class="btn-show" @click="showQuote = !showQuote">{{ showQuote == false ? 'Посмотреть напутствие' : 'Вернуться к видео'}}</button>
            </div>


          </div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
	export default {
		name:'user',
		data(){
			return {
				category: this.$route.query.categories || '',
				quote:'',
        showQuote:false,
        video:{},
			}
		},
		components:{},
		mounted(){
      console.log('true')
			let user_history = 10
			let array = {category:this.category, user_history:user_history}

			let validateCategories = ''
			let dataCategories = []

			if(typeof localStorage.user_info == "undefined") {

				localStorage.user_info = 'session'


				this.CREATE_SESSION(array)
			} else {
				this.CREATE_SESSION(array)
			}
			this.GET_QUOTE() // Получение цитаты
			this.GET_NOTIFICCATIONS_API() //Получение уведомления о окончании цитат

		},
		methods:{
      videoSize(){
        let width = 'width="100%"'
        let video = this.UPDATE_QUOTE.video_link?.split(' ') || ['']
        video[1] = width
        return this.UPDATE_QUOTE.video_link
      },
      onPlayerReady(event) {
        console.log(event) // Воспроизводим видео сразу после готовности
        console.log()
      },
      onPlayerStateChange(event){
        console.log(event)
        event.target.isMuted()
      },
      playVideo(){
        console.log(this.video)


      },
		...mapMutations({
			USER_GET_QUOTE:'USER_GET_QUOTE',
  		}),
  		...mapActions({
			CREATE_SESSION:'CREATE_SESSION',
			GET_NOTIFICCATIONS_API:'GET_NOTIFICCATIONS_API'
  		}),
  			creat_history(){

  			},
  		GET_QUOTE(){
  			this.USER_GET_QUOTE(this.category)
  		}
		},
		computed:{
		...mapGetters([
			'GET_USER_QUOTE',
			'GET_API_NOTIFICCATIONS'
  		]),
  		UPDATE_QUOTE:function(){
  			return this.quote = JSON.parse(localStorage.getItem("quote")) || this.GET_USER_QUOTE
  		},
		},
	}
</script>

<style scoped>
.p_citata {
	font-size:26px;
	font-weight: bold;
	text-align:left;
	margin:17px 0 0 0 ;
}
.video {
  display:flex;
  width:100% !important;
  justify-content: center;
  position: relative;
  top:0;
  left:0;
  max-width: 100%;
  margin-bottom: 20px;
}
.h1_citata {
	font-size: 15px;
    padding: 6px 21px;
	background:#F472B6;
	border-radius:17px;
	margin:0;
	width: 91px;
  margin-top: 10px;
}
.btn-show {
  background:#F472B6;
  border:none;
  outline:none;
  padding: 6px 21px;
  border-radius: 5px;
  font-size: 17px;
  color:#fff;
  margin-top: 30px;
  margin-bottom: 30px;
}
.box {
	//margin-left:40px;
  width:100%;
  height: 65vh;
}
.position_relative {
	position:relative;
}
.position_abslt {
	position:absolute;
	top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}
.user_size {
  height: 100vh !important;
  background-color:#121127;
  color:#fff;
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20%;
}
.user {
	  background-color:#121127;
	  color:#fff;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-bottom: 20%;
}
.border_citate {
	border-bottom:solid 1px  #A9A9A9;
	width:100%;
	margin:16px auto 16px auto;
	display:flex;
	padding-bottom: 34px;
  height: auto;
}
.btn {
		background:rgba(255, 255, 255, 0.08);
		color:#fff;
		width: 20%;
		padding:15px 32px;
		line-height: 10px;
		display:block;
		margin:0 auto;
		font-size: 16px;
		cursor:pointer;
		border-radius:5px;
		transition:all 300ms ease;
		border:rgba(255, 255, 255, 0.08) 1px solid;
	}
	.btn:hover {
		background: rgba(255, 255, 255, 0.12);
		color:#fff;
		transition:all 300ms ease;
		border:rgba(255, 255, 255, 0.12) 1px solid;
	}
	.btn:active {
		transition:all 300ms ease;
		border:rgba(255, 255, 255, 0.2) 1px solid;
	}
.text_warning {
	color:#ff6e70;
}
.text-btn {
  display:flex;
  flex-direction: column;
  height:auto;
  align-content: center;
}
@media (max-width: 991px) {
	.btn {
		width:100%;
	}
	.border_citate {
		width:100%;
	}
}
.image-box{
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  height:100%;

}
.image {
  max-width:50%;
  max-height: 250px;
}
.main-box{
  display: flex;
  flex-direction: column;
  height: auto;
}
</style>